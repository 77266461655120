import { IEvent } from '@studyportals/student-interfaces';
import { EventType, StudentRepositoryStateType } from '../enumerations';

export class AnonymousStudentStateChanged implements IEvent {
	public static EventType: string = EventType.AnonymousStudentStateChanged;
	public eventType: string = AnonymousStudentStateChanged.EventType;

	constructor(
		public readonly timestamp: Date,
		public readonly oldState: StudentRepositoryStateType,
		public readonly newState: StudentRepositoryStateType
	) {

	}

}
