import { IStudent, StudentField } from '@studyportals/studentdomain';
import { IAnonymousStudentService } from '../../interfaces/application';
import { Actor, InterestType } from '../../interfaces/enumerations';
import { CatchReportAsyncException } from '../decorators/error-decorators';
import { StudentRepository } from '../domain/student-repository';

export class AnonymousStudentService implements IAnonymousStudentService {
	constructor(
		private studentRepository: StudentRepository,
	) {

	}

	@CatchReportAsyncException
	public async setStudentData(studentData: IStudent, actor: Actor = Actor.USER): Promise<void> {
		await this.studentRepository.setStudentData(studentData, actor);
	}

	@CatchReportAsyncException
	public async getStudentData(studentFields: StudentField[]): Promise<IStudent> {
		return this.studentRepository.getStudentData(studentFields);
	}

	@CatchReportAsyncException
	public async getStudentDataCompleteness(studentFields: StudentField[]): Promise<number> {
		return this.studentRepository.getStudentDataCompleteness(studentFields);
	}

	@CatchReportAsyncException
	public async addDisciplines(ids: number[]): Promise<void> {
		return this.studentRepository.addDisciplines(ids);
	}

	@CatchReportAsyncException
	public async removeDisciplines(ids: number[]): Promise<void> {
		return this.studentRepository.removeDisciplines(ids);
	}

	@CatchReportAsyncException
	public async addInterest(type: InterestType, ids: number[]): Promise<void> {
		return this.studentRepository.addInterest(type, ids);
	}

	@CatchReportAsyncException
	public async removeInterest(type: InterestType, ids: number[]): Promise<void> {
		return this.studentRepository.removeInterest(type, ids);
	}

	@CatchReportAsyncException
	public async setName(name: string): Promise<void> {
		return this.studentRepository.setName(name);
	}

	@CatchReportAsyncException
	public async setGPA(grade_type: string, grade_value: any): Promise<void> {
		return this.studentRepository.setGPA(grade_type, grade_value);
	}
}
